import { Container, Row, Col } from "react-bootstrap";
import styles from "./Footer.module.css";
import logo from "../../assets/img/Vaadya.png";
import {
  FaPhoneAlt,
  FaFacebookSquare,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { HiMailOpen, HiLocationMarker } from "react-icons/hi";

const Footer = () => {
  return (
    <div className={`bg-dark pt-4`}>
      <Container className="pl-0 pr-0">
        <Row className={`text-light mx-auto ${styles.Size}`}>
          <Col>
            <Row className="mb-3" style={{ borderBottom: "1px solid #fff" }}>
              <Col sm={4}>
                <Row className="mt-3">
                  <Col xs={4}>
                    <img className={styles.Img} src={logo} alt="logo" />
                  </Col>
                  <Col>
                    <h3 className="font-weight-bold">Vaadya Music Academy</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-center">
                      Excellence. Wisdom. Creativity
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col className="mb-4" sm={4}>
                <p className="font-weight-bold text-center">Contact</p>
                <p className={`mb-0 pb-0 ${styles.LeftMar}`}>
                  <FaPhoneAlt /> Phone: 9810747798
                </p>
                <p className={`mb-0 pb-0 ${styles.LeftMar}`}>
                  <HiMailOpen /> Email: musicacademyvaadya@gmail.com
                </p>
                <p className={`mb-0 pb-0 ${styles.LeftMar}`}>
                  <HiLocationMarker /> B-58/1, B Block, East of Kailash, Delhi,
                  New Delhi, Delhi 110065
                </p>
              </Col>
              <Col className="mb-4" sm={4}></Col>
            </Row>
            <Row>
              <Col className="text-center">
                <p></p>

                <p>
                  <small>2024 Vaadya Music Academy. All Rights Reserved.</small>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
