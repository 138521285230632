import { Switch, Route } from "react-router-dom";
import Home from "../../components/Home/Home";
import Profile from "../../components/Profile/Profile";
import Visi from "../../components/Visi/Visi";
import Contact from "../../components/Contact/Contact";
import Header from "../../components/Header/Header";
import Post from "../../components/Post/Post";
import NotFound from "../../components/404/NotFound";

const Main = (props) => {
    return (
        <Switch>
            <Route exact path="/">
                <Header />
                <Home />
            </Route>
            <Route path="/profile">
                <Profile />
            </Route>
            <Route path="/about">
                <Visi />
            </Route>
            <Route path="/contact">
                <Contact />
            </Route>
            <Route path="/post/:id">
                <Post />
            </Route>
            <Route path="*">
                <NotFound />
            </Route>
        </Switch>
    );
};

export default Main;
