const sambutan = [{
    text: "Expert Guidance",
    img: "http://sman2kendal.sch.id/media_library/images/e800e553755d81431cc14f0f9fd64a99.jpg",
    isi: `
At VAADYA, we believe that music is a universal language that brings people together. Our experienced instructor specializes in teaching music in a way that is simple, plain, and beautifully engaging. From the basics of Sa Re Ga Ma to more advanced techniques, our lessons are designed to help you connect with music on a deeper level.

Why Choose Us?
Tailored Instruction: Each lesson is customized to fit your learning style and pace. Whether you're interested in traditional music theory or modern practices, our teaching approach is flexible and personalized.

Expert Teacher: Learn from a skilled instructor with years of experience in teaching music. Our teacher has a passion for making music accessible and enjoyable, ensuring that each lesson is both educational and inspiring.

Simple and Beautiful Methods: We focus on teaching music in a way that is easy to understand and beautifully presented. Our approach demystifies complex concepts, making them approachable and fun.

Comprehensive Curriculum: From fundamental concepts like Sa Re Ga Ma to more advanced techniques, our curriculum covers a wide range of musical elements to provide a well-rounded education.

Start Your Musical Journey
Embarking on your musical journey has never been easier. Our straightforward and engaging lessons will guide you step-by-step, helping you build a strong foundation and gain confidence in your musical abilities.

Ready to explore the world of music with expert guidance? Contact us today to schedule your first lesson and take the first step towards mastering your musical dreams.
    `
}];

export default sambutan;
