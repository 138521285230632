import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

const Contact = () => {
  useEffect(() => {
    document.title = "Contact | Vaadya";
  }, []);

  return (
    <Container>
      <Row>
        <Col className="mt-4 mb-4">
          <h3 className="font-weight-bold mb-3">Contact</h3>
          <p className="mb-0">Phone: 9810747798</p>
          <p className="mb-0">Email: musicacademyvaadya@gmail.com</p>
          <p className="mb-0">
            Address: B-58/1, B Block, East of Kailash, Delhi, New Delhi, Delhi
            110065
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d876.078093498093!2d77.2533367753433!3d28.560381172478518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce37fc74ec3ef%3A0xe88b5fb1e1ba185e!2sValley%20of%20Kids%20Play%20School%20and%20Day%20Care!5e0!3m2!1sen!2sin!4v1724778601337!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
