import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState } from "react";

import logo from "../../assets/img/Vaadya.png";
import styles from "./NavBar.module.css";

const NavBar = () => {
    const [openToggle, setOpenToggle] = useState(false);

    const toggleHandler = () => setOpenToggle(!openToggle);
    const closeNavHandler = () => setOpenToggle(false);

    return (
        <Navbar
            expand="lg"
            className={`pt-2 h-20 ${styles.BlurBackground}`}
            variant="light"
            expanded={openToggle}
            sticky="top"
        >
            <Container>
                <Link to="/">
                    <Navbar.Brand onClick={closeNavHandler}>
                        <img
                            src={logo}
                            height={35}
                            className={`d-inline-block align-top pr-2 ${styles.BorderRight}`}
                            alt="React Bootstrap logo"
                        />
                        <span>
                            <div className={`${styles.Title} ${styles.Top}`}>
                                VAADYA
                            </div>
                            <div className={`${styles.Title} ${styles.Bottom}`}>
                                MUSIC ACADEMY
                            </div>
                        </span>
                    </Navbar.Brand>
                </Link>

                <Navbar.Toggle
                    onClick={toggleHandler}
                    className={styles.Toggle}
                    aria-controls="basic-navbar-nav"
                />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto font-weight-bold">
                        <Link
                            className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
                            to="/profile"
                            onClick={closeNavHandler}
                        >
                            Profile
                        </Link>
                        <Link
                            className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
                            to="/about"
                            onClick={closeNavHandler}
                        >
                            About
                        </Link>
                        <Link
                            className={`pr-3 ${styles.Black} ${styles.CenterNavItem}`}
                            to="/contact"
                            onClick={closeNavHandler}
                        >
                            Contact
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
